import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Container,
  Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useUIContext } from '../context/UIContext'; // Import the UIContext
import logo from '../assets/favicon.ico'; // Import your logo

const Header = () => {
  const { isSidebarOpen, toggleSidebar, viewport } = useUIContext(); // Use the context

  const navItems = [
    { label: 'Features', href: '/#features' },
    { label: 'Pricing', href: '/#pricing' },
    { label: 'Dashboard', href: '/#dashboard' },
    { label: 'Contact', href: '/#contact' },
    { label: 'Blog', href: '/blog' },
    // { label: 'Login', href: '#login' }
  ];

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{
        padding: '10px 0',
        boxShadow: 'none',
        background: 'linear-gradient(135deg, #f0f9ff 0%, #cbebff 100%)', // Gradient background
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {/* Logo and Brand Name */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <img
              src={logo}
              alt="NasRadius Logo"
              style={{
                height: viewport === 'mobile' ? '30px' : '40px', // Responsive logo size
                marginRight: '10px',
              }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 700,
                letterSpacing: '0.5px',
                fontSize: { xs: '1.5rem', md: '2rem' },
                background: 'linear-gradient(90deg, #008080, #4A90E2)', // Updated gradient
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                cursor: 'pointer',
              }}
            >
              NasRadius
            </Typography>
          </Box>

          {viewport === 'mobile' ? (
            // Mobile View: Hamburger Menu
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={toggleSidebar}
            >
              <MenuIcon sx={{ fontSize: 30, color: '#4A90E2' }} />
            </IconButton>
          ) : (
            // Desktop/Tablet View: Full Menu
            <Box sx={{ display: 'flex', gap: '20px' }}>
              {navItems.map((item, index) => (
                <Button
                  key={index}
                  href={item.href}
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 500,
                    color: '#2C3E50', // Softer, modern blue-gray color
                    fontSize: '1rem',
                    textTransform: 'none',
                    position: 'relative', // For the hover underline effect
                    transition: 'color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                      color: '#008080', // Color change on hover
                      transform: 'scale(1.1)', // Slightly enlarge on hover
                      '&::after': {
                        width: '100%', // Expand underline on hover
                      },
                    },
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -4,
                      left: 0,
                      width: 0,
                      height: '2px',
                      backgroundColor: '#008080', // Underline color
                      transition: 'width 0.3s ease',
                    },
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          )}

          {/* Sidebar Drawer for Mobile */}
          <Drawer
            anchor="right"
            open={isSidebarOpen}
            onClose={toggleSidebar}
          >
            <Box
              sx={{ width: 250, background: '#f0f0f0', height: '100%' }}
              role="presentation"
              onClick={toggleSidebar}
              onKeyDown={toggleSidebar}
            >
              <List>
                {navItems.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    component="a"
                    href={item.href}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item.label}
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        color: '#2C3E50',
                        padding: '10px 0',
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;