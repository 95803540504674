import React from 'react';
import { Container, Typography, Grid, Box, Paper } from '@mui/material';

const features = [
  {
    title: 'Automated Billing',
    description: 'Save time with seamless, recurring billing for customers.',
  },
  {
    title: 'Integrated Payments',
    description: 'Accept payments via MPesa, credit cards, and more.',
  },
  {
    title: 'Hotspot Billing',
    description: 'Manage user logins, track usage, and automate billing for hotspot networks.',
  },
  {
    title: 'PPPoE Billing',
    description: 'Offer secure PPPoE connections and automate billing for these services.',
  },
  {
    title: 'Static IP Management',
    description: 'Handle static IP assignments with ease and integrate it into your billing system.',
  },
  {
    title: 'Customer Management',
    description: 'Easily track and manage customer data, balances, and more.',
  },
  {
    title: 'SMS Alerts',
    description: 'Notify customers of overdue payments or plan renewals.',
  },
];

const Features = () => {
  return (
    <Box
      component="section"
      id="features"
      sx={{
        padding: { xs: '40px 16px', sm: '60px 24px', md: '80px 32px' },
        backgroundColor: '#f9f9f9',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            color: '#2C3E50', // Updated main title color
            marginBottom: '40px',
          }}
        >
          Why Choose NasRadius?
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={3}
                sx={{
                  padding: '20px',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)', // Slightly enlarge on hover
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
                  },
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 600,
                    color: '#008080', // Updated title color to Teal
                    marginBottom: '10px',
                    transition: 'color 0.3s ease',
                    '&:hover': {
                      color: '#FF5A5F', // Change color on hover
                    },
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    color: '#555',
                  }}
                >
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;