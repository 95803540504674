import React from 'react';
import { Typography, Button, Box, Grid, Container } from '@mui/material';
import desktopDashboard from '../assets/desktop-dashboard.webp';
import mobileDashboard from '../assets/mobile-dashboard.webp';
import { useUIContext } from '../context/UIContext'; // Import UIContext

const HeroSection = () => {
  const { viewport } = useUIContext(); // Use viewport from UIContext

  return (
    <Box
      sx={{
        padding: { xs: '20px 16px', sm: '40px 24px', md: '80px 32px' },
        position: 'relative',
        background: 'linear-gradient(135deg, #f0f9ff 0%, #cbebff 100%)', // Soft gradient background
        // borderRadius: '15px',
        overflow: 'hidden',
        width: '100%', // Make sure it spans full width
      }}
    >
      <Container maxWidth="lg">
      <Grid container spacing={4} alignItems="center">
        {/* Left Section: Text */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '2rem', sm: '2.8rem', md: '3.5rem' },
              lineHeight: { xs: '1.3', md: '1.1' },
              marginBottom: { xs: '16px', md: '24px' },
              color: '#2C3E50',
              animation: 'fadeIn 1s ease-in-out', // Simple fade-in animation
              '@keyframes fadeIn': {
                '0%': { opacity: 0, transform: 'translateY(20px)' },
                '100%': { opacity: 1, transform: 'translateY(0)' },
              },
            }}
          >
            Automate Your ISP Billing with{' '}
            <span
              style={{
                background: 'linear-gradient(90deg, #00c6ff, #0072ff)', // Enhanced gradient
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              NasRadius
            </span>
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary"
            paragraph
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.4rem' },
              lineHeight: { xs: '1.6', md: '1.5' },
              marginBottom: { xs: '20px', sm: '24px', md: '32px' },
              color: '#4A4A4A',
            }}
          >
            NasRadius helps ISPs automate billing, manage customers, and simplify network
            management. Get started now and transform your ISP operations.
          </Typography>
          <Button
            variant="contained"
            size="large"
            href='#contact' // Add href to scroll to contact section
            sx={{
              fontSize: { xs: '0.9rem', md: '1.1rem' },
              padding: { xs: '10px 20px', md: '14px 28px' },
              background: 'linear-gradient(90deg, #0072ff, #00c6ff)', // Gradient button
              color: '#fff',
              fontWeight: 600,
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 114, 255, 0.2)', // Subtle shadow
              textTransform: 'none',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0px 8px 20px rgba(0, 114, 255, 0.4)',
              },
            }}
          >
            Get Started
          </Button>
        </Grid>

        {/* Right Section: Dashboard Image Composition */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-block',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {/* Decorative Shape (Optional SVG Shape or Overlay) */}
            <Box
              sx={{
                position: 'absolute',
                top: '-20%',
                left: '-20%',
                width: '150%',
                height: '150%',
                background: 'radial-gradient(circle at center, rgba(0, 114, 255, 0.2), transparent)',
                borderRadius: '50%',
                zIndex: 0,
              }}
            />

            {/* Desktop Dashboard Image */}
            <img
              src={desktopDashboard}
              alt="NasRadius Desktop Dashboard"
              style={{
                width: viewport === 'mobile' ? '75%' : '85%',
                maxWidth: '100%',
                height: 'auto',
                boxShadow: '0px 15px 30px rgba(0,0,0,0.1)',
                borderRadius: '20px',
                position: 'relative',
                zIndex: 1,
                animation: 'float 3s ease-in-out infinite', // Floating effect
                '@keyframes float': {
                  '0%': { transform: 'translateY(0px)' },
                  '50%': { transform: 'translateY(-10px)' },
                  '100%': { transform: 'translateY(0px)' },
                },
              }}
            />
            {/* Mobile Dashboard Image - Always Rendered */}
            <img
              src={mobileDashboard}
              alt="NasRadius Mobile Dashboard"
              style={{
                position: 'absolute',
                top: viewport === 'mobile' ? '0' : viewport === 'tablet' ? '0' : '-50px',
                right: viewport === 'mobile' ? '0' : viewport === 'tablet' ? '0' : '-50px',
                width: viewport === 'mobile' ? '30%' : viewport === 'tablet' ? '35%' : '40%',
                maxWidth: '100%',
                height: 'auto',
                boxShadow: '0px 15px 30px rgba(0,0,0,0.15)',
                borderRadius: '20px',
                transform: 'rotate(-10deg)',
                zIndex: 2,
                overflow: 'hidden',
                animation: 'float 3s ease-in-out infinite alternate', // Subtle floating animation
              }}
            />
          </Box>
        </Grid>
      </Grid>
      </Container>
    </Box>
  );
};

export default HeroSection;