import React from 'react';
import { Container, Typography, Grid, Paper, Box } from '@mui/material';

const plans = [
  {
    title: 'Hotspot Only',
    price: '1,500',
    features: [
      'Automated billing for hotspot networks',
      'User management for hotspot',
      'Usage tracking and analytics',
      'Basic customer support',
    ],
  },
  {
    title: 'Hotspot, PPPoE and Static IP',
    price: '3,000',
    features: [
      'Everything in Hotspot Only plan',
      'Automated billing for PPPoE services',
      'Secure PPPoE user management',
      'Static IP assignment',
      'Advanced customer support',
    ],
  },
];

const Pricing = () => {
  return (
    <Box
      component="section"
      id="pricing"
      sx={{
        padding: { xs: '40px 16px', sm: '60px 24px', md: '80px 32px' },
        backgroundColor: '#f4f4f4',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            color: '#2C3E50',
            marginBottom: '40px',
          }}
        >
            Flexible Pricing Plans
        </Typography>
        <Grid container spacing={4}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper
                elevation={4}
                sx={{
                  padding: '30px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)', // Slightly enlarge on hover
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
                  },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 700,
                    color: '#008080', // Teal color for the plan title
                    marginBottom: '20px',
                  }}
                >
                  {plan.title}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 600,
                    color: '#333',
                    marginBottom: '20px',
                  }}
                >
                  Ksh {plan.price} / month
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    listStyleType: 'none',
                    padding: 0,
                    marginBottom: '20px',
                    textAlign: 'left',
                    color: '#555',
                  }}
                >
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "'Poppins', sans-serif",
                          marginBottom: '8px',
                        }}
                      >
                        • {feature}
                      </Typography>
                    </li>
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Pricing;