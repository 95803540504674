import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// You can choose a theme you like
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';


const posts = [
  // ... existing articles
  {
    id: 3,
    title: 'Implementing Load Balancing on Mikrotik Routers',
    content: `
### Introduction

Load balancing is essential for network environments where multiple internet connections are used to distribute traffic efficiently. Mikrotik routers offer powerful capabilities to implement load balancing, ensuring optimal bandwidth utilization and redundancy. In this article, we'll walk you through setting up load balancing using Mikrotik's terminal commands.

#### Prerequisites

- A Mikrotik router with at least two WAN interfaces.
- Basic knowledge of Mikrotik's RouterOS and terminal commands.
- Access to the router's terminal interface.

---

#### Step 1: Identify Your Interfaces

First, identify the interfaces connected to your internet service providers (ISPs). We'll assume the following for this tutorial:

- **WAN1**: \`ether1\`
- **WAN2**: \`ether2\`
- **LAN**: \`ether3\`

You can list all interfaces using:

\`\`\`bash
/interface print
\`\`\`

---

#### Step 2: Configure IP Addresses

Assign IP addresses to your WAN interfaces if not already configured.

\`\`\`bash
# Configure WAN1
/ip address add address=192.168.1.2/24 interface=ether1

# Configure WAN2
/ip address add address=192.168.2.2/24 interface=ether2

# Configure LAN
/ip address add address=192.168.88.1/24 interface=ether3
\`\`\`

---

#### Step 3: Set Up Default Routes

Add default routes for both WAN connections with different routing marks.

\`\`\`bash
# Add default route for WAN1
/ip route add dst-address=0.0.0.0/0 gateway=192.168.1.1 routing-mark=to_WAN1 check-gateway=ping

# Add default route for WAN2
/ip route add dst-address=0.0.0.0/0 gateway=192.168.2.1 routing-mark=to_WAN2 check-gateway=ping

# Add main default routes with distances
/ip route add dst-address=0.0.0.0/0 gateway=192.168.1.1 distance=1 check-gateway=ping
/ip route add dst-address=0.0.0.0/0 gateway=192.168.2.1 distance=2 check-gateway=ping
\`\`\`

---

#### Step 4: Configure Mangle Rules

Use mangle rules to mark connections and routes for load balancing.

\`\`\`bash
# Mark connections coming from LAN
/ip firewall mangle
add chain=prerouting in-interface=ether3 connection-mark=no-mark action=mark-connection new-connection-mark=WAN1_conn passthrough=yes per-connection-classifier=both-addresses-and-ports:2/0
add chain=prerouting in-interface=ether3 connection-mark=no-mark action=mark-connection new-connection-mark=WAN2_conn passthrough=yes per-connection-classifier=both-addresses-and-ports:2/1

# Mark routing based on connection marks
add chain=prerouting connection-mark=WAN1_conn in-interface=ether3 action=mark-routing new-routing-mark=to_WAN1 passthrough=yes
add chain=prerouting connection-mark=WAN2_conn in-interface=ether3 action=mark-routing new-routing-mark=to_WAN2 passthrough=yes
\`\`\`

---

#### Step 5: Configure NAT Rules

Set up Network Address Translation (NAT) for both WAN interfaces.

\`\`\`bash
/ip firewall nat
add chain=srcnat out-interface=ether1 action=masquerade
add chain=srcnat out-interface=ether2 action=masquerade
\`\`\`

---

#### Step 6: Adjust Firewall Filters (Optional)

Ensure that the firewall rules allow the traffic through the router.

\`\`\`bash
# Accept established and related connections
/ip firewall filter
add chain=forward connection-state=established,related action=accept

# Drop invalid connections
add chain=forward connection-state=invalid action=drop

# Accept new connections from LAN to WAN
add chain=forward in-interface=ether3 out-interface=ether1 action=accept
add chain=forward in-interface=ether3 out-interface=ether2 action=accept

# Drop all other forward traffic
add chain=forward action=drop
\`\`\`

---

#### Step 7: Test the Configuration

To verify that load balancing is working:

1. Generate traffic from multiple devices in your LAN.
2. Use the Torch tool or connection tracking to observe the traffic distribution.

---

#### Conclusion

You've successfully configured load balancing on your Mikrotik router using terminal commands. This setup distributes outbound traffic between two WAN interfaces, optimizing bandwidth usage and providing redundancy.

---

**Disclaimer:** Adjust IP addresses, interface names, and gateway addresses according to your network configuration. Always backup your current configuration before making changes to your router.
    `,
    slug: 'implementing-load-balancing-mikrotik',
    imageUrl: 'https://via.placeholder.com/800x400', // Replace with your image URL
  },
];

const BlogArticle = () => {
  const { slug } = useParams();
  
  const article = posts.find((post) => post.slug === slug);

  if (!article) {
    return (
      <Container maxWidth="md" sx={{ marginTop: '40px' }}>
        <Typography
          variant="h6"
          align="center"
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            color: '#2C3E50',
            marginBottom: '20px',
          }}
        >
          Article not found
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="md"
      sx={{ marginTop: '40px', padding: { xs: '20px', md: '40px' } }}
    >
      <Box
        sx={{
          marginBottom: '20px',
          borderRadius: '8px',
          overflow: 'hidden',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <img
          src={article.imageUrl}
          alt={article.title}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </Box>
      <Box
        sx={{
          padding: '20px',
          backgroundColor: '#fff',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 700,
            color: '#2C3E50',
            marginBottom: '20px',
          }}
        >
          {article.title}
        </Typography>
        <ReactMarkdown
          children={article.content}
          remarkPlugins={[remarkGfm]}
          components={{
            h1: ({ node, ...props }) => (
              <Typography
                variant="h4"
                sx={{ fontWeight: 700, marginTop: '20px' }}
                {...props}
              />
            ),
            h2: ({ node, ...props }) => (
              <Typography
                variant="h5"
                sx={{ fontWeight: 600, marginTop: '20px' }}
                {...props}
              />
            ),
            h3: ({ node, ...props }) => (
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, marginTop: '20px' }}
                {...props}
              />
            ),
            p: ({ node, ...props }) => (
              <Typography
                variant="body1"
                sx={{ marginBottom: '16px' }}
                {...props}
              />
            ),
            li: ({ node, ordered, ...props }) => (
              <li>
                <Typography
                  component="span"
                  variant="body1"
                  {...props}
                />
              </li>
            ),
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  style={materialLight}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <Box
                  component="code"
                  sx={{
                    backgroundColor: '#f5f5f5',
                    padding: '2px 4px',
                    borderRadius: '4px',
                    fontFamily: 'monospace',
                  }}
                  {...props}
                >
                  {children}
                </Box>
              );
            },
            // Map other Markdown elements as needed
          }}
        />
      </Box>
    </Container>
  );
};

export default BlogArticle;