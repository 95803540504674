import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import ShowCaseCard from './ShowCaseCard'; // Import the new component
import { useUIContext } from '../context/UIContext';
import incomeMetrics from '../assets/income-metrics.png';
import monthlyRevenue from '../assets/monthly-revenues.png';
import weeklyRevenue from '../assets/weekly-revenues.png';
import customersPage from '../assets/customers-page.png';
import customerNetworkUsage from '../assets/customer-network-usage.png';
import planSetup from '../assets/plan-setup.png';
import vouchersPage from '../assets/vouchers-page.png';

const DashboardShowcase = () => {
    const { viewport } = useUIContext();

    // Adjust slidesPerView based on viewport
    const getSlidesPerView = () => {
        if (viewport === 'mobile') return 1;
        if (viewport === 'tablet') return 2;
        return 3;
    };

    // Define the slide data
    const slideData = [
        {
            image: incomeMetrics,
            altText: "View Your Analytics",
            title: "View Your Analytics",
            description: "Monitor your metrics in real-time for better decision-making.",
        },
        {
            image: monthlyRevenue,
            altText: "Track Your Monthly Revenue",
            title: "Track Your Monthly Revenue",
            description: "Visualize and analyze your monthly revenue trends.",
        },
        {
            image: weeklyRevenue,
            altText: "View Your Weekly Revenue",
            title: "View Your Weekly Revenue",
            description: "Gain insights into your daily and weekly earnings.",
        },
        {
            image: customersPage,
            altText: "Manage Customers",
            title: "Manage Customers",
            description: "Easily track and manage customer details and activity.",
        },
        {
            image: customerNetworkUsage,
            altText: "Visualize Customer Network Usage",
            title: "Visualize Customer Network Usage",
            description: "Track network usage for efficient resource management.",
        },
        {
            image: planSetup,
            altText: "Setup Internet Plans",
            title: "Setup Internet Plans",
            description: "Create and customize internet plans to meet customer needs.",
        },
        {
            image: vouchersPage,
            altText: "Manage Vouchers",
            title: "Manage Vouchers",
            description: "Generate and monitor vouchers for your customers.",
        }
    ];

    return (
        <Box
            sx={{
                backgroundColor: '#fff',
                borderRadius: '20px', // Rounded corners for the container
                padding: { xs: '40px 16px', md: '60px 40px' },
                margin: { xs: '20px 0', md: '40px 0' },
            }}
            id="dashboard"
        >
            <Container maxWidth="lg">
                <Typography
                    variant="h2"
                    gutterBottom
                    align="center"
                    sx={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 700,
                        background: 'linear-gradient(90deg, #008080, #4A90E2)', // Gradient effect
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        letterSpacing: '1px',
                        fontSize: { xs: '1.8rem', md: '3rem' },
                        marginBottom: '40px',
                    }}
                >
                    See NasRadius in Action
                </Typography>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={getSlidesPerView()}
                    loop={true}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Pagination, Autoplay]}
                    style={{
                        paddingBottom: '40px',
                    }}
                >
                    {slideData.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <ShowCaseCard
                                image={slide.image}
                                altText={slide.altText}
                                title={slide.title}
                                description={slide.description}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </Box>
    );
};

export default DashboardShowcase;