import React, { createContext, useContext, useEffect, useState } from 'react';

const UIContext = createContext();

export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false); // Default to 'false' to keep it closed initially
  const [viewport, setViewport] = useState('desktop'); // 'mobile', 'tablet', 'desktop'

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setViewport('mobile');
        setSidebarOpen(false); // Automatically collapse sidebar in mobile view
      } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
        setViewport('tablet');
        setSidebarOpen(false); // Default to closed in tablet view
      } else {
        setViewport('desktop');
        setSidebarOpen(false); // Default to closed in desktop view
      }
    };

    handleResize(); // Call at component mount to set initial state
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <UIContext.Provider value={{ isSidebarOpen, toggleSidebar, viewport }}>
      {children}
    </UIContext.Provider>
  );
};
