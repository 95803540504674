import React from "react";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";

const ShowCaseCard = ({ image, altText, title, description }) => (
  <Card
    sx={{
      borderRadius: '15px', // Rounded corners
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)', // Softer shadow
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        transform: 'translateY(-10px)', // Slide up on hover
        boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
      },
      overflow: 'hidden', // Ensure no overflow from images
    }}
  >
    <CardMedia
      component="img"
      height="250" // Adjusted height for better visuals
      image={image}
      alt={altText}
      sx={{
        transition: 'transform 0.5s ease', // Smooth zoom effect on hover
        '&:hover': {
          transform: 'scale(1.05)', // Slight zoom on hover
        },
      }}
    />
    <CardContent
      sx={{
        padding: '20px', // Adjusted padding for better spacing
        backgroundColor: '#fff', // White background for clarity
      }}
    >
      <Typography
        variant="h5"
        component="div"
        sx={{
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600,
          color: '#2C3E50', // Darker text for better contrast
          fontSize: { xs: '1.3rem', md: '1.6rem' },
          marginBottom: '10px', // Additional spacing below the title
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 400,
          color: '#555',
          fontSize: { xs: '1rem', md: '1.1rem' }, // Slightly larger font for better readability
          lineHeight: 1.6, // Improved line height for clarity
        }}
      >
        {description}
      </Typography>
    </CardContent>
  </Card>
);

export default ShowCaseCard;