import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Box } from '@mui/material';

const posts = [
  {
    title: 'Introduction to NasRadius',
    description: 'Learn how NasRadius revolutionizes ISP billing.',
    imageUrl: 'https://via.placeholder.com/300', // Replace with your image URL
    slug: 'introduction-to-nasradius',
  },
  {
    title: 'Automating ISP Billing',
    description: 'A guide on automating billing processes for efficiency.',
    imageUrl: 'https://via.placeholder.com/300', // Replace with your image URL
    slug: 'automating-isp-billing',
  },
  {
    title: 'Implementing Load Balancing on Mikrotik Routers',
    description: 'Step-by-step guide on setting up load balancing with terminal commands.',
    imageUrl: 'https://via.placeholder.com/300', // Replace with your image URL
    slug: 'implementing-load-balancing-mikrotik',
  },
  // Add more posts as needed
];

const BlogPage = () => {
  return (
    <Box
      component="section"
      id="blog"
      sx={{
        padding: { xs: '40px 16px', sm: '60px 24px', md: '80px 32px' },
        backgroundColor: '#f9f9f9',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            color: '#2C3E50',
            marginBottom: '40px',
          }}
        >
          Blog Articles
        </Typography>
        <Grid container spacing={4}>
          {posts.map((post, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={post.imageUrl}
                  alt={post.title}
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 600,
                      color: '#008080',
                      marginBottom: '10px',
                      transition: 'color 0.3s ease',
                      '&:hover': {
                        color: '#FF5A5F',
                      },
                    }}
                  >
                    {post.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "'Poppins', sans-serif",
                      color: '#555',
                      marginBottom: '10px',
                    }}
                  >
                    {post.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "'Poppins', sans-serif",
                      color: '#008080',
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() => (window.location.href = `/blog/${post.slug}`)}
                  >
                    Read More
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default BlogPage;