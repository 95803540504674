import React, { useState } from "react";
import { Container, Typography, TextField, Button, Dialog, DialogTitle, DialogContent, Box } from "@mui/material";
import { useUIContext } from '../context/UIContext'; // Import the UIContext
import emailjs from 'emailjs-com'; // Import EmailJS

const LeadCaptureForm = () => {
    const { viewport } = useUIContext(); // Use viewport from UIContext

    const [dialogOpen, setDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subdomain: '',
    });

    // Update form data when input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Close dialog handler
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     // Send email using EmailJS
    //     emailjs.send(
    //       'service_xi01lf4',       // Replace with your EmailJS service ID
    //       'template_egmdypw',      // Replace with your EmailJS template ID
    //       {
    //         name: formData.name,
    //         email: formData.email,
    //         phone: formData.phone,
    //         subdomain: formData.subdomain,
    //       },
    //       '_v35uOF0GqMrB9Pja'            // Replace with your EmailJS user ID
    //     )
    //     .then((response) => {
    //         console.log('SUCCESS!', response.status, response.text);
    //         setDialogOpen(true);
    //     })
    //     .catch((err) => {
    //         console.error('FAILED...', err);
    //         alert('Failed to send email. Please try again.');
    //     });
    // };


    const handleSubmit = (e) => {
        e.preventDefault();
      
        // Send email to you (Admin)
        emailjs.send(
          'service_h277a9a',       // Replace with your EmailJS service ID
          'template_egmdypw',      // Replace with your EmailJS template ID for admin
          {
            to_name: 'Evans',                   // Static name for you, the admin
            name: formData.name,                // Matches the {{name}} placeholder
            email: formData.email,              // Matches the {{email}} placeholder
            phone: formData.phone,              // Matches the {{phone}} placeholder
            subdomain: formData.subdomain || 'N/A', // Matches the {{subdomain}} placeholder
          },
          '_v35uOF0GqMrB9Pja'            // Replace with your EmailJS user ID
        )
        .then((response) => {
          console.log('Admin Email SUCCESS!', response.status, response.text);
      
          // Send confirmation email to the user
          emailjs.send(
            'service_h277a9a',            // Same service ID
            'template_7rz3aah', // Replace with the new Template ID for user confirmation
            {
              to_name: formData.name,        // User's name for personalized email
              name: formData.name,           // Same as provided in the form
              email: formData.email,         // User's email
              phone: formData.phone,         // User's phone
              subdomain: formData.subdomain || 'N/A', // User's preferred subdomain or 'N/A'
            },
            '_v35uOF0GqMrB9Pja'                 // Same User ID
          )
          .then((response) => {
            console.log('User Confirmation Email SUCCESS!', response.status, response.text);
            setDialogOpen(true); // Show dialog on successful submission
          })
          .catch((err) => {
            console.error('User Confirmation FAILED...', err);
            alert('Failed to send confirmation email to the user.');
          });
        })
        .catch((err) => {
          console.error('Admin Email FAILED...', err);
          alert('Failed to send email. Please try again.');
        });
      };

    // Responsive padding based on viewport
    const getPadding = () => {
        if (viewport === 'mobile') return '30px 10px';
        if (viewport === 'tablet') return '50px 20px';
        return '60px 0';
    };

    return (
        <Box
            sx={{
                backgroundColor: '#f9f9f9',
                borderRadius: '15px', // Rounded corners for the container
                padding: getPadding(), // Responsive padding
                // boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.1)', // Soft shadow effect
                textAlign: 'center',
                margin: { xs: '20px 0', md: '40px 0' },
            }}
            id="contact" // Add ID for scroll navigation
        >
            <Container maxWidth="sm">
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 700,
                        background: 'linear-gradient(90deg, #008080, #4A90E2)', // Gradient for the title
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        letterSpacing: '0.5px',
                        fontSize: { xs: '1.8rem', md: '2.5rem' }, // Responsive font size
                        marginBottom: '20px',
                    }}
                >
                    Request a Free Demo
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                        color: '#555',
                        marginBottom: { xs: '20px', md: '30px' }, // Responsive margin
                        fontSize: { xs: '0.9rem', md: '1rem' }, // Responsive font size
                    }}
                >
                    Fill out the form below and we will contact you to schedule a demo.
                    <br />
                    Get a free subdomain for your ISP business.
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Name"
                        name="name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        InputLabelProps={{
                            style: {
                                fontFamily: "'Poppins', sans-serif",
                            }
                        }}
                        InputProps={{
                            style: {
                                fontFamily: "'Poppins', sans-serif",
                            }
                        }}
                    />
                    <TextField
                        label="Email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        required
                        onChange={handleChange}
                        InputLabelProps={{
                            style: {
                                fontFamily: "'Poppins', sans-serif",
                            }
                        }}
                        InputProps={{
                            style: {
                                fontFamily: "'Poppins', sans-serif",
                            }
                        }}
                    />
                    <TextField
                        label="Phone"
                        name="phone"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        required
                        InputLabelProps={{
                            style: {
                                fontFamily: "'Poppins', sans-serif",
                            }
                        }}
                        InputProps={{
                            style: {
                                fontFamily: "'Poppins', sans-serif",
                            }
                        }}
                    />
                    <TextField
                        label="Preferred Subdomain (optional) e.g smartisp.nasradius.com"
                        name="subdomain"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        InputLabelProps={{
                            style: {
                                fontFamily: "'Poppins', sans-serif'",
                            }
                        }}
                        InputProps={{
                            style: {
                                fontFamily: "'Poppins', sans-serif'",
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        sx={{
                            margin: '20px 0',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 500,
                            borderRadius: '8px',
                            padding: { xs: '10px 20px', md: '12px 24px' }, // Responsive padding for button
                            fontSize: { xs: '0.875rem', md: '1rem' }, // Responsive font size
                            transition: 'background-color 0.3s ease',
                            backgroundColor: '#008080', // Consistent color with branding
                            '&:hover': {
                                backgroundColor: '#4A90E2', // Hover color change
                            }
                        }}
                    >
                        Request Demo
                    </Button>
                </form>
                {/* Dialog for Successful Submission */}
                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                >
                    <DialogTitle>Form Submitted Successfully</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Thank you, {formData.name}! Your request has been received. We will contact you soon.
                        </Typography>
                    </DialogContent>
                </Dialog>
            </Container>
        </Box>
    );
};

export default LeadCaptureForm;