import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Features from './components/Features';
import Pricing from './components/Pricing';
import DashboardShowcase from './components/DashboardShowcase';
import LeadCaptureForm from './components/LeadCaptureForm';
import { UIProvider } from './context/UIContext';
import BlogPage from './components/BlogPage'; // Import the BlogPage
import BlogArticle from './components/BlogArticle'; // Import the BlogArticle

import './App.css';
import { CssBaseline } from '@mui/material';

function MainContent() {
  const location = useLocation();
  const { slug } = useParams(); // Capture the slug parameter if present

  const isBlogPage = location.pathname === '/blog';
  const isBlogArticle = Boolean(slug); // Check if a slug exists

  return (
    <>
      <Header />
      {isBlogPage && <BlogPage />}
      {isBlogArticle && <BlogArticle />} {/* Render BlogArticle if a slug exists */}
      {!isBlogPage && !isBlogArticle && (
        <>
          <HeroSection />
          <Features />
          <Pricing />
          <DashboardShowcase />
          <LeadCaptureForm />
        </>
      )}
    </>
  );
}

function App() {
  return (
    <UIProvider>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Add routes with dynamic parameters for blog articles */}
          <Route path="/blog/:slug" element={<MainContent />} />
          <Route path="/blog" element={<MainContent />} />
          {/* Catch-all route for main content */}
          <Route path="/*" element={<MainContent />} />
        </Routes>
      </Router>
    </UIProvider>
  );
}

export default App;